import React from 'react';

type Props = {
    name: 'finance' | 'menu' | 'close';
};

function Icon({ name }: Props) {
    return (
        <div>
            {(() => {
                switch (name) {
                    case 'close':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                            </svg>
                        );
                    case 'menu':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                            </svg>
                        );
                    case 'finance':
                    default:
                        return (
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path
                                    fill="currentColor"
                                    d="M271.06 144.3l54.27 14.3a8.59 8.59 0 016.63 8.1c0 4.6-4.09 8.4-9.12 8.4h-35.6a30 30 0 01-11.19-2.2c-5.24-2.2-11.28-1.7-15.3 2l-19 17.5a11.68 11.68 0 00-2.25 2.66 11.42 11.42 0 003.88 15.74 83.77 83.77 0 0034.51 11.5V240c0 8.8 7.83 16 17.37 16h17.37c9.55 0 17.38-7.2 17.38-16v-17.6c32.93-3.6 57.84-31 53.5-63-3.15-23-22.46-41.3-46.56-47.7l-54.27-14.3a8.59 8.59 0 01-6.63-8.1c0-4.6 4.09-8.4 9.12-8.4h35.6A30 30 0 01332 83.1c5.23 2.2 11.28 1.7 15.3-2l19-17.5a11.31 11.31 0 002.17-2.6 11.43 11.43 0 00-3.84-15.78 83.82 83.82 0 00-34.52-11.5V16c0-8.8-7.82-16-17.37-16h-17.37C285.82 0 278 7.2 278 16v17.6c-32.89 3.6-57.85 31-53.51 63 3.14 23 22.51 41.3 46.57 47.7zm294.21 183.8c-11.8-10.7-30.2-10-42.6 0l-92.4 73.9a63.64 63.64 0 01-40 14H272a16 16 0 010-32h78.29c15.9 0 30.71-10.9 33.25-26.6a31.2 31.2 0 00.46-5.46A32 32 0 00352 320H192a117.66 117.66 0 00-74.1 26.29L71.4 384H16a16 16 0 00-16 16v96a16 16 0 0016 16h356.77a64 64 0 0040-14L564 377a32 32 0 001.28-48.9z"
                                />
                            </svg>
                        );
                }
            })()}
        </div>
    );
}

export default Icon;
