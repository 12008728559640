import React from 'react';
import { Link } from 'gatsby';

import * as s from './styles.module.scss';

type Props = {
    link: string;
    linkName: string;
    icon?: React.ReactElement;
};

function Promotion({ children, link, linkName, icon }: React.PropsWithChildren<Props>) {
    return (
        <div className={s.wrapper}>
            {icon && <div className={s.icon}>{icon}</div>}
            <div className={s.text}>{children}</div>
            <div className={s.cta}>
                {link.startsWith('http') ? (
                    <a className={s.button} href={link}>
                        {linkName}
                    </a>
                ) : (
                    <Link className={s.button} to={link}>
                        {linkName}
                    </Link>
                )}
            </div>
        </div>
    );
}

export default Promotion;
