// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--25OSo";
export var header = "styles-module--header--1nv0f";
export var container = "styles-module--container--B_01r";
export var imageWrapper = "styles-module--image-wrapper--1KhcN";
export var info = "styles-module--info--2wK5p";
export var menuInfo = "styles-module--menu-info--3ZXFF";
export var menuButton = "styles-module--menu-button--2dAAH";
export var closeButton = "styles-module--close-button---QiYf";
export var overlay = "styles-module--overlay--xSW_3";
export var open = "styles-module--open--2ppwO";
export var nav = "styles-module--nav--1zylD";
export var link = "styles-module--link--37e_c";
export var active = "styles-module--active--2M3v3";