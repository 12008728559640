import * as React from 'react';
import { Link } from 'gatsby';

import * as s from './styles.module.scss';

function Footer() {
    return (
        <footer className={s.footer}>
            <div className={s.body}>
                <div>
                    <h5>Contact</h5>
                    <p>
                        Phone: <a href="tel:+14107032474">(410) 703-2474</a>
                        <br />
                        Email: <a href="mailto:jason@pomcontracting.com">jason@pomcontracting.com</a>
                    </p>
                </div>
                <div className={s.links}>
                    <h5>Links</h5>
                    <Link to="/">Home</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/about">About Us</Link>
                </div>
            </div>
            <div className={s.wrapper}>© {new Date().getFullYear()} Peace of Mind Contracting, LLC</div>
        </footer>
    );
}

export default Footer;
