import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface IServiceBlocksQuery {
    allMdx: {
        nodes: Array<{
            slug: string;
            frontmatter: {
                title: string;
                description: string;
                home?: boolean;
                featured: {
                    alt: string;
                    src: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
        }>;
    };
}

export const useServiceBlocksQuery = () =>
    useStaticQuery<IServiceBlocksQuery>(graphql`
        query {
            allMdx(
                filter: { fileAbsolutePath: { regex: "/content.services/" } }
                sort: { fields: frontmatter___title }
            ) {
                nodes {
                    slug
                    frontmatter {
                        title
                        description
                        home
                        featured {
                            alt
                            src {
                                childImageSharp {
                                    gatsbyImageData(
                                        aspectRatio: 2.5
                                        layout: CONSTRAINED
                                        width: 600
                                        transformOptions: { cropFocus: CENTER }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
