/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';

import Header from '../Header';
import Footer from '../Footer';

import './reset.scss';
import './global.scss';
import './fonts.scss';

function Layout({ children }: React.PropsWithChildren<{}>) {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    );
}

export default Layout;
