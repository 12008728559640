import React from 'react';

import * as s from './styles.module.scss';

type Props = {
    type: string;
    name: string;
    title: string;
    placeholder: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function Input({ type, title, placeholder, value, onChange, name }: Props) {
    const id = title.toLowerCase().replace(/\W/g, '');

    return (
        <label className={s.label} htmlFor={id}>
            <span className={s.title}>{title}</span>
            {type !== 'textarea' ? (
                <input
                    className={s.input}
                    name={name}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            ) : (
                <textarea
                    className={s.textarea}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            )}
        </label>
    );
}

export default Input;
