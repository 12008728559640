import * as React from 'react';

import * as s from './styles.module.scss';

type Props = {
    maxWidth?: number;
    className?: string;
};

function Container({ children, maxWidth, className }: React.PropsWithChildren<Props>) {
    return (
        <div className={[s.container, className].filter(Boolean).join(' ')} style={{ maxWidth }}>
            {children}
        </div>
    );
}

export default Container;
