/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Props = {
    title: string;
    description?: string;
    lang?: string;
    keywords?: string[];
    meta?: Array<{
        name: string;
        content: string;
    }>;
};

function SEO({ description = '', lang = 'en', meta = [], keywords = [], title }: Props) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet>
            <html lang={lang} />
            <title>{`${title} | ${site.siteMetadata.title}`}</title>
            <meta name="description" content={metaDescription} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:type" content="website" />
            <meta name="keywords" content={keywords.join(', ')} />
            {meta.forEach(({ name, content }) => (
                <meta name={name} content={content} />
            ))}
        </Helmet>
    );
}

export default SEO;
