import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as s from './styles.module.scss';

type Props = {
    title: string;
    description: string;
    image: IGatsbyImageData;
    imageAlt: string;
    link: string;
};

function ServiceBlock({ title, description, image, imageAlt, link }: Props) {
    return (
        <div>
            <div className={s.imageWrapper}>
                <Link to={link}>
                    <GatsbyImage image={image} alt={imageAlt} />
                </Link>
            </div>
            <h4 className={s.title}>{title}</h4>
            <p className={s.description}>{description}</p>
            <Link className={s.link} to={link}>
                Learn More
            </Link>
        </div>
    );
}

export default ServiceBlock;
