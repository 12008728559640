import React from 'react';

import { useServiceBlocksQuery } from '@queries';
import ServiceBlock from '../ServiceBlock';

import * as s from './styles.module.scss';

type Props = {
    featured?: boolean;
};

function ServiceBlockGroup({ featured }: Props) {
    const {
        allMdx: { nodes },
    } = useServiceBlocksQuery();
    const services = featured ? nodes.filter((node) => node.frontmatter.home) : nodes;

    return (
        <div className={s.blocks}>
            {services.map((service) => (
                <div key={service.slug}>
                    <ServiceBlock
                        title={service.frontmatter.title}
                        description={service.frontmatter.description}
                        image={service.frontmatter.featured.src.childImageSharp.gatsbyImageData}
                        imageAlt={service.frontmatter.featured.alt}
                        link={`/${service.slug}`}
                    />
                </div>
            ))}
        </div>
    );
}

export default ServiceBlockGroup;
