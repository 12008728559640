import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Container, Icon } from '@components';

import * as s from './styles.module.scss';

function Header() {
    const [isOpen, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                setOpen(false);
            }
        };

        window.addEventListener('keydown', handler);

        return () => window.removeEventListener('keydown', handler);
    });

    return (
        <div className={s.wrapper}>
            <header className={s.header}>
                <Container className={s.container}>
                    <div className={s.imageWrapper}>
                        <Link to="/">
                            <StaticImage
                                src="../../images/pom-logo.jpg"
                                alt="Peace of Mind Contracting logo"
                                layout="constrained"
                                width={240}
                                placeholder="blurred"
                                loading="eager"
                            />
                        </Link>
                    </div>
                    <div className={s.info}>
                        <p>Give us a call</p>
                        <a href="tel:+14107032474">(410) 703-2474</a>
                        <p>Licensed and Insured. MHIC #133742</p>
                    </div>
                    <button className={s.menuButton} type="button" onClick={handleOpen}>
                        <Icon name="menu" />
                    </button>
                    <nav className={[s.nav, isOpen && s.open].filter(Boolean).join(' ')}>
                        <button className={s.closeButton} type="button" onClick={handleClose}>
                            <Icon name="close" />
                        </button>
                        <Link className={s.link} to="/" activeClassName={s.active} onClick={handleClose}>
                            Home
                        </Link>
                        <Link
                            className={s.link}
                            to="/services"
                            activeClassName={s.active}
                            partiallyActive
                            onClick={handleClose}
                        >
                            Services
                        </Link>
                        <Link className={s.link} to="/contact" activeClassName={s.active} onClick={handleClose}>
                            Contact Us
                        </Link>
                        <Link className={s.link} to="/about" activeClassName={s.active} onClick={handleClose}>
                            About Us
                        </Link>
                        <div className={s.menuInfo}>
                            <p>Give us a call</p>
                            <a href="tel:+14107032474">(410) 703-2474</a>
                            <p>Licensed and Insured. MHIC #133742</p>
                        </div>
                    </nav>
                </Container>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className={[s.overlay, isOpen && s.open].filter(Boolean).join(' ')} onClick={handleClose} />
            </header>
        </div>
    );
}

export default Header;
